/**
 * A composable for managing a list of unique strings with toggle behavior.
 * Useful for tracking states (e.g., items being downloaded, selected, or toggled).
 *
 * Usage example:
 * const { stringList, toggleString } = useToggleStringList();
 * toggleString("some-item"); // Adds "some-item" to the list
 * toggleString("some-item"); // Removes "some-item" from the list
 */
export function useToggleStringList() {
  /**
   * `stringList` holds the list of strings in a reactive array
   */
  const stringSet = ref(new Set<string>())

  /**
   * Toggles the presence of a string in the list.
   * If the string is already in `stringList`, it removes it.
   * Otherwise, it adds the string to `stringList`.
   *
   * @param value - The string to be toggled in the list.
   */
  function toggleString(value: string) {
    if (stringSet.value.has(value)) {
      // If the string is in the Set, remove it
      stringSet.value.delete(value)
    } else {
      stringSet.value.add(value)
    }
  }

  // Return both the list and the toggle function to be used in components
  return {
    stringSet,
    toggleString,
  }
}
